import PropTypes from 'prop-types';

import { SquareBox } from 'shared/components/SquareBox';
import { Icon } from 'shared/components/Icon';
import { SelectedModelVariations } from 'shared/components/SelectedModelVariations';

export function ModeloSelecionado({
  tecido,
  abrirCatalogoTecidos,
  removerAmostra,
  tecidoUsuario,
  removerTecidoUsuario,
  modelo,
  variacao,
  children,
}) {
  return (
    <div className="modelo-selecionado">
      <div className="opcoes">
        <SquareBox>
          <div className="tecido" onClick={() => abrirCatalogoTecidos()}>
            {tecido || tecidoUsuario ? (
              <div>
                <img
                  src={tecidoUsuario ? tecidoUsuario.images.thumbnail.url : tecido.images.thumbnail.url}
                  alt="Tecido selecionado"
                  width="150px"
                  crossOrigin="anonymous"
                />
                <span className="image-remove" onClick={tecidoUsuario ? removerTecidoUsuario : removerAmostra}>
                  <Icon icon="x" />
                </span>
              </div>
            ) : (
              <span className="sem-tecido">
                Selecione <br />
                um tecido
              </span>
            )}
          </div>
        </SquareBox>
        <SelectedModelVariations model={modelo} variation={variacao} />
        {children}
      </div>
    </div>
  );
}

ModeloSelecionado.propTypes = {
  children: PropTypes.any,
  tecido: PropTypes.object,
  modelo: PropTypes.object,
  variacao: PropTypes.string,
  selecionarVariacao: PropTypes.func,
  abrirCatalogoTecidos: PropTypes.func,
  removerAmostra: PropTypes.func,
  tecidoUsuario: PropTypes.object,
  removerTecidoUsuario: PropTypes.func,
};
