import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';
import { SVGModelo } from 'shared/components/SVGModelo';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { getValue } from 'shared/helpers/getters-setters';
import { useModels } from 'shared/http/models.http';
import { useSearch } from 'shared/helpers/useSearch';
import { sortModelsBySubgroup } from 'shared/models';
import { trackViewedModels } from 'shared/analytics';

export function ModelosByTipo({ tipo, selecionarModelo, voltar, tecido, tecidoUsuario }) {
  const goBack = (e) => {
    e.preventDefault();
    voltar();
  };

  const { 'tipo-modelo': tipoModeloId } = useSearch();

  const { models: _modelos, isLoading } = useModels(
    {
      modelTypeId: tipoModeloId,
    },
    Boolean(tipo._id)
  );
  const modelos = useMemo(() => sortModelsBySubgroup(_modelos), [_modelos]);

  useEffect(() => {
    if (!modelos || modelos.length === 0) {
      return;
    }

    trackViewedModels(modelos);
  }, [modelos]);

  if (!modelos) {
    return null;
  }

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="modelos-by-tipo">
      <div className="menu">
        <a href="" onClick={goBack}>
          <Icon icon="left-arrow" />
        </a>

        <span className="titulo">{tipo.name}</span>
      </div>

      <div className="modelos">
        {modelos.map((modelo) => (
          <div key={modelo._id} className="modelo" onClick={() => selecionarModelo(modelo)}>
            <div>
              <div className="svg">
                <SVGModelo
                  svg={getValue(modelo, 'default_unfolding.nodes')}
                  tecido={tecido ? tecido : null}
                  tecidoUsuario={tecidoUsuario ? tecidoUsuario : null}
                />
              </div>
              <div className="label">{modelo.code}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

ModelosByTipo.propTypes = {
  tipo: PropTypes.object,
  selecionarModelo: PropTypes.func,
  voltar: PropTypes.func,
  tecido: PropTypes.object,
  tecidoUsuario: PropTypes.object,
};
