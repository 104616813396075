import { useEffect, useState } from 'react';

import { addItemIntoShoppingCart } from 'shared/http/shopping-cart.http';
import { getVariationIndexByCode } from 'shared/models';
import { Icon } from 'shared/components/Icon';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { MoldOrder } from 'shared/MoldOrder';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { useModel } from 'shared/http/models.http';
import { usePreferences } from 'shared/http/preferences.http';
import { useSearch } from 'shared/helpers/useSearch';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useUserFabric } from 'shared/http/user-fabrics.http';

import { TamanhosSelecionados } from './TamanhosSelecionados';
import { SucessoCompraMolde } from './SucessoCompraMolde';
import { SelecaoTamanhosModelo } from './SelecaoTamanhosModelo';
import { MedidasModeloProva } from './MedidasModeloProva';
import { ConfirmacaoCompraMolde } from './ConfirmacaoCompraMolde';

const steps = ['medidas', 'tamanho', 'tamanhosSelecionados', 'confirmacao', 'sucesso'];

export function CompraMoldePage() {
  const { modelo: modeloId, 'amostra-tecido': amostraId, variacao, 'tecido-usuario': userFabricId } = useSearch();
  const { navigateBack } = useNavigate();

  const [actualStep, setActualStep] = useState(0);

  const [order, setOrder] = useState(null);

  const { model: modeloSelecionado, isLoading: isLoadingModelo } = useModel(modeloId);
  const { fabricToken: amostraTecidoSelecionada, isLoading: isLoadingTecido } = useFabricToken(amostraId);
  const { userFabric: selectedUserFabric, isLoading: isLoadingUserFabric } = useUserFabric(userFabricId);
  const { preferences: preferencias, isLoading: isLoadingPreferencias } = usePreferences();

  const isLoading = isLoadingModelo || isLoadingTecido || isLoadingPreferencias || isLoadingUserFabric;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const selectedModelVariation = getVariationIndexByCode(modeloSelecionado, variacao);

    MoldOrder.init(modeloSelecionado, selectedModelVariation, amostraTecidoSelecionada, preferencias, selectedUserFabric).then((order) => {
      setOrder(order);
    });
  }, [isLoading, modeloSelecionado, amostraTecidoSelecionada, variacao]);

  function goToNextStep() {
    if (actualStep >= steps.length) {
      return;
    }

    setActualStep((step) => step + 1);
  }

  function goToPreviousStep() {
    if (actualStep === 0) {
      navigateBack();

      return;
    }

    setActualStep((step) => step - 1);
  }

  function isActive(step) {
    return steps[actualStep] === step;
  }

  function update(_order) {
    const newOrderReference = _order.clone();

    setOrder(newOrderReference);

    return newOrderReference;
  }

  function buy() {
    return addItemIntoShoppingCart(order._order);
  }

  function goBack(e) {
    e.preventDefault();
    goToPreviousStep();
  }

  if (isLoading || !order) {
    return <LoaderIcon />;
  }

  return (
    <div className="compra-molde-page">
      <div className="menu">
        <div className="d-flex justify-content-between">
          <a href="" onClick={goBack}>
            <Icon icon="left-arrow" />
          </a>

          <span />
        </div>
      </div>

      {isActive('medidas') ? (
        <MedidasModeloProva order={order} update={update} avancar={goToNextStep} />
      ) : isActive('tamanho') ? (
        <SelecaoTamanhosModelo order={order} update={update} avancar={goToNextStep} />
      ) : isActive('tamanhosSelecionados') ? (
        <TamanhosSelecionados order={order} update={update} avancar={goToNextStep} />
      ) : isActive('confirmacao') ? (
        <ConfirmacaoCompraMolde order={order} onBuy={buy} onProceed={goToNextStep} />
      ) : isActive('sucesso') ? (
        <SucessoCompraMolde />
      ) : (
        <em>Erro</em>
      )}
    </div>
  );
}
