import { hydrate } from 'react-dom';
import { Router } from 'react-router-dom';
import vhCheck from 'vh-check';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { history } from 'shared/helpers/history';
import { ErrorPage } from 'shared/components/ErrorPage';
import { setupGA } from 'shared/analytics';
import { WhatsAppBtn } from 'shared/components/WhatsAppBtn';

import { MobileApp } from './MobileApp';

import './mobile.scss';

vhCheck();

setupGA();

// Allow the passed state to be garbage-collected
window.__PRELOADED_STATE__ = null;

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

hydrate(
  <Router history={history}>
    <WhatsAppBtn />
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <MobileApp />
    </Sentry.ErrorBoundary>
  </Router>,
  document.getElementById('mobile-root')
);

if ('serviceWorker' in navigator) {
  if (['production', 'staging'].includes(process.env.NODE_ENV)) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/sw.js')
        .then((registration) => {
          // eslint-disable-next-line no-console
          console.log('SW registered: ', registration);
        })
        .catch((registrationError) => {
          // eslint-disable-next-line no-console
          console.log('SW registration failed: ', registrationError);
        });
    });
  } else {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}
