import { useState } from 'react';

import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useCartPayment } from 'shared/useCartPayment';
import { createOrder } from 'shared/http/orders.http';
import { AcceptContract } from 'shared/payment/AcceptContract';
import { trackCheckoutPurchase } from 'shared/analytics';

export function AceitacaoContratoVenda() {
  const { contractAccepted, setContractAccepted, billingAddress, cupomText, backToStart, proximo, setOrder } = useCartPayment();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isLoadingTerms, setIsLoadingTerms] = useState(true);

  function onAccept() {
    setContractAccepted(true);
  }

  function next() {
    if (!contractAccepted) {
      return;
    }

    createOrder({
      billingAddress,
      codigoCupom: cupomText,
    })
    .then((order) => {
      trackCheckoutPurchase(order);
      setOrder(order);
      proximo();
    }, (err) => {
      setIsLoading(false);
      setError(err.message);
    });
  }

  if (isLoading) {
    return <LoaderIcon />;
  }

  if (error) {
    return (
      <div className="passo">
        <div className="payment-error">
          <h2>Ops! Ocorreu um erro ao iniciar o pagamento do seu pedido:</h2>

          <p className="payment-error-detail">
            {error}
          </p>

          <p>Revise seus dados cadastrais ou tente novamente</p>
        </div>

        <div className="acao">
          <button
            onClick={backToStart}
            type="button"
            className="btn btn-lg btn-block btn-primary"
          >
            Voltar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="passo">
      <AcceptContract setIsLoadingTerms={setIsLoadingTerms} onAccept={onAccept} />

      <div className="acao">
        <button
          type="button"
          className="btn btn-lg btn-block btn-primary"
          disabled={!contractAccepted || isLoadingTerms}
          onClick={next}
        >
          Pagar
        </button>
      </div>
    </div>
  );
}
