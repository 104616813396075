import PropTypes from 'prop-types';
import { Form, useFormControl } from 'react-bootstrap-utils';

import { formatMoney } from 'shared/helpers/formatters';
import { FormCustomSwitch } from 'shared/components/FormCustomSwitch';
import { Icon } from 'shared/components/Icon';
import { noop } from 'shared/helpers/utils';

export function SelecaoTamanhosModelo({ order, update, avancar }) {
  const table = order.getTable();

  function moreThanOneSelectedMeasure() {
    const measure = table.measures.filter(({ selected }) => selected);

    return measure.length >= 1;
  }

  function updateTotal({ measures }) {
    const newOrderReference = order.clone();

    newOrderReference.updateMeasureTable(measures);
    newOrderReference.updateTotal();

    update(newOrderReference);
  }

  return (
    <div className="passo selecao-tamanhos-modelo">
      <div className="content">
        <Form initialValues={{ measures: table.measures }} onChange={updateTotal} onSubmit={noop} customActions={<></>}>
          <FormMeasuresTable name="measures" order={order} />
        </Form>
      </div>

      <div className="acao">
        <button className="btn btn-lg btn-block btn-primary" onClick={avancar} disabled={!moreThanOneSelectedMeasure()}>
          Próximo
        </button>
      </div>
    </div>
  );
}

SelecaoTamanhosModelo.propTypes = {
  avancar: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

function FormMeasuresTable({ name, order }) {
  const { getValue } = useFormControl(name);
  const measures = getValue();

  return measures.map((measure, index) => (
    <div key={measure._id} className="tamanho">
      <div className="row">
        <div className="col-2">
          <div className="modelo-prova">{measure.referenceModel && <Icon icon="tamanho-prova" />}</div>
        </div>

        <div className="col-3 descricao">
          {measure.name} ({measure.size})
        </div>

        <div className="col-3 seletor">
          <FormCustomSwitch name={`${name}[${index}].selected`} />
        </div>

        <div className="col-4 valor">{measure.selected ? formatMoney(order.getSizePrice()) : ''}</div>
      </div>
    </div>
  ));
}
