import { useEffect, useState } from 'react';
import { Form, FormGroupInput, useAlertDialog } from 'react-bootstrap-utils';

import { SidebarOpener } from 'shared/components/SidebarOpener';
import { saveUserFabric, uploadUserFabric } from 'shared/http/user-fabrics.http';
import { useNavigate } from 'shared/helpers/useNavigate';
import { LoaderIcon } from 'shared/components/LoaderIcon';

export function UploadTecidoUsuarioPage() {
  const [files, setFiles] = useState([]);

  const [imagemUrl, setImagemUrl] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const { navigate } = useNavigate();
  const { DialogPortal: UploadErrorAlertPortal } = useAlertDialog({
    title: 'Erro',
    message: 'Não conseguimos processar a sua imagem, tente novamente com uma imagem menor.',
  });

  function uploadFabric(e) {
    setFiles(e.target.files);
  }

  function saveFabric(userFabricInfo) {
    const formData = new FormData();

    formData.append('imagemOriginal', files[0], files[0].name);
    setIsSaving(true);
    uploadUserFabric(formData)
      .then((userFabric) => saveUserFabric(userFabric._id, userFabricInfo))
      .then((userFabric) => {
        setIsSaving(false);
        navigate('/', { 'tecido-usuario': userFabric._id });
      })
      .catch(() => {
        setIsSaving(false);
      });
  }

  useEffect(() => {
    if (files.length === 0) {
      return;
    }

    const imagem = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(imagem);
    reader.onloadend = (data) => {
      setImagemUrl(data.target.result);
    };
  }, [files]);

  return (
    <div className="upload-tecido-page">
      <UploadErrorAlertPortal />

      <div className="menu">
        <SidebarOpener />
        <div className="titulo">Novo tecido </div>
        <span />
      </div>

      <Form
        initialValues={{}}
        onSubmit={saveFabric}
        customActions={files.length > 0 ? (
          <div className="acao">
            <button className="btn btn-lg btn-block btn-primary" disabled={isSaving}>{isSaving ? (<><LoaderIcon /> Salvando</>) : 'Salvar'}</button>
          </div>
        ) : <></>}
      >
        {files.length === 0 ? (
          <div className="custom-file">
            <input type="file" className="custom-file-input" onChange={uploadFabric} accept=".jpg, .png, .jpeg" />
            <label className="custom-file-label" htmlFor="customFile">
              Selecione uma imagem
            </label>
          </div>
        ) : (
          <>
            <div>
              <img src={imagemUrl} className="box-imagem" alt="tecido selecionado para importar" crossOrigin="anonymous" />
            </div>
            <FormGroupInput label="Código" name="codigo" required />
            <FormGroupInput label="Descrição" name="descricao" required />
            <FormGroupInput label="Contato" name="contato" />
          </>
        )}
      </Form>
    </div>
  );
}
