import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';

import { SVGCurva } from './SVGCurva';
import { SVGLogo } from './SVGLogo';

export function TopoPedido({ topoRegistro, textos, goBack }) {
  return (
    <div className="topo-pedido">
      <div className={`quadrado${topoRegistro ? ' topo-registro' : ''}`}>
        <div className="menu py-3">
          <div className="arrow">
            <a href="" className="ml-3" onClick={goBack}>
              <Icon icon="left-arrow" />
            </a>
          </div>
          <div className="div-logo float-left">
            <SVGLogo />
          </div>
        </div>
        <div className="mensagem">
          {textos.map((texto) => (
            <p key={texto}>{texto}</p>
          ))}
        </div>
      </div>
      <div className="meio-circulo">
        <SVGCurva />
      </div>
    </div>
  );
}
TopoPedido.propTypes = {
  topoRegistro: PropTypes.bool,
  textos: PropTypes.arrayOf(PropTypes.string),
  goBack: PropTypes.func,
};
