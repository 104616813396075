import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { SidebarOpener } from 'shared/components/SidebarOpener';
import { formatDate, formatMoney } from 'shared/helpers/formatters';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { orderStatusMap } from 'shared/orders/orders';
import { NoOrders } from 'shared/orders/NoOrders';
import { useOrders } from 'shared/http/orders.http';

export function OrdersPage() {
  const { orders, isLoading } = useOrders();

  const pedidos = useMemo(() => {
    orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return orders;
  }, [orders]);

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="my-orders-page">
      <div className="menu">
        <SidebarOpener />
        <div className="titulo">Meus Pedidos</div>
      </div>

      <div className="conteudo container-fluid">
        <div className="listagem">
          {pedidos.length === 0 ? (
            <div className="pt-2">
              <NoOrders />
            </div>
          ) : (
            pedidos.map((pedido) => (
              <Link key={pedido._id} to={`/meus-pedidos/${pedido._id}`} className="item">
                <div className="d-flex justify-content-between">
                  <span>{formatDate(pedido.createdAt)}</span>

                  <span>{formatMoney(pedido.total)}</span>
                </div>
                {orderStatusMap[pedido.status]}
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
