import PropTypes from 'prop-types';

import { SVGLogo } from './SVGLogo';
import { SVGCurva } from './SVGCurva';

export function Topo({ textos = [], topoRegistro = false }) {
  return (
    <div className="topo">
      <div className={`quadrado${topoRegistro ? ' topo-registro' : ''}`}>
        <div>
          <SVGLogo />
        </div>
        <div>
          {textos.map((texto) => (
            <p key={texto} className="mensagem">
              {texto}
            </p>
          ))}
        </div>
      </div>
      <div>
        <SVGCurva />
      </div>
    </div>
  );
}
Topo.propTypes = {
  textos: PropTypes.arrayOf(PropTypes.string),
  topoRegistro: PropTypes.bool,
};
