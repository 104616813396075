import PropTypes from 'prop-types';

import { formatMoney } from 'shared/helpers/formatters';
import { Icon } from 'shared/components/Icon';

import { Manequim } from '../../catalog/Manequim';

export function ConfirmacaoCompraMolde({ order, onBuy, onProceed }) {
  const orderTotal = order.getTotal();
  const sizes = order._order.sizes;

  function buy() {
    onBuy().then(onProceed);
  }

  return (
    <div className="passo confirmacao-compra-molde">
      <div className="content">
        <div className="conteudo">
          <div className="detalhes">
            <Manequim
              modo="completo"
              modelo={order._order.model}
              variacao={order._order.modelVariation}
              tecido={order._order.fabricToken}
              tecidoUsuario={order._order.userFabric}
            />

            <div className="tamanhos">
              <p className="text-right titulo">Tamanhos:</p>
              {sizes.map((size) => (
                <div key={size.name} className="tamanho">
                  <div className="icone">
                    {size.name === order.getSizeName() && (
                      <div className="tamanho-prova">
                        <Icon icon="tamanho-prova" />
                      </div>
                    )}
                  </div>
                  <div className="descricao">
                    {size.name} ({size.size})
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <p className="total">Total</p>
            <div className="box-total">{formatMoney(orderTotal)}</div>
          </div>
        </div>
      </div>

      <div className="acao">
        <button type="button" className="btn btn-lg btn-block btn-primary" onClick={buy}>
          Confirmar
        </button>
      </div>
    </div>
  );
}

ConfirmacaoCompraMolde.propTypes = {
  onBuy: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};
