import { useEffect, useRef } from 'react';

//FIXME: este componente me parece desnecessário
export function SVGLogo() {
  const svgRef = useRef();

  function trimSVG() {
    const svg = svgRef.current;

    const dimensoes = svg.getBBox();

    svg.viewBox.baseVal.width = dimensoes.width * 0.7;
    svg.viewBox.baseVal.height = ((dimensoes.width * 0.7) / 264.001) * 34.932;
    svg.children[1].setAttribute(
      'transform',
      `translate(${(-47.5 * (dimensoes.width * 0.7)) / 264.001} ${
        (-127.5 * (dimensoes.width * 0.7)) / 264.001
      }) scale(${(dimensoes.width * 0.7) / 264.001})`
    );
  }

  useEffect(() => {
    trimSVG();
  }, []);

  return (
    <svg className="logo" ref={svgRef} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 0 0">
      <defs>
        <filter id="Caminho_81" x="228.799" y="0.002" width="35.202" height="34.925" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_82" x="130.741" y="0.001" width="32.138" height="34.929" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-2" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_83" x="32.684" y="0" width="35.206" height="34.93" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-3" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_84" x="65.37" y="0" width="35.207" height="34.929" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-4" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_85" x="0" y="0.002" width="35.168" height="34.93" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-5" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_86" x="98.058" y="0.001" width="35.204" height="34.928" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-6" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-6" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_87" x="196.112" y="0" width="35.206" height="34.928" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-7" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-7" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="Caminho_88" x="163.47" y="0" width="35.169" height="34.932" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur-8" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-8" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Grupo_2582" data-name="Grupo 2582" transform="">
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_81)">
          <path
            id="Caminho_81-2"
            data-name="Caminho 81"
            d="M247.449,8.792a7.674,7.674,0,0,0-6.44-6.351V0h-2.379V2.44a7.674,7.674,0,0,0-6.44,6.351h-2.471v2.347h2.471a7.676,7.676,0,0,0,6.44,6.351v2.437h2.379V17.49a7.674,7.674,0,0,0,6.44-6.351h2.471V8.792Zm-2.423,2.348a5.312,5.312,0,0,1-4.017,3.962V12.887h-2.379V15.1a5.309,5.309,0,0,1-4.017-3.962h2.244V8.792h-2.244a5.312,5.312,0,0,1,4.017-3.962V7.044h2.379V4.831a5.309,5.309,0,0,1,4.017,3.962h-2.245v2.347Z"
            transform="translate(6.58 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_82)">
          <path
            id="Caminho_82-2"
            data-name="Caminho 82"
            d="M141.369,16.39a6.425,6.425,0,1,1,0-12.849,6.539,6.539,0,0,1,4.5,1.778l2.538-2.5A10.14,10.14,0,0,0,141.369,0a10.034,10.034,0,0,0-10.1,9.965,10.034,10.034,0,0,0,10.1,9.964,10.138,10.138,0,0,0,7.035-2.817l-2.538-2.5A6.538,6.538,0,0,1,141.369,16.39Z"
            transform="translate(6.97 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_83)">
          <path
            id="Caminho_83-2"
            data-name="Caminho 83"
            d="M42.918,0a10.034,10.034,0,0,0-10.1,9.964,10.1,10.1,0,0,0,20.206,0A10.034,10.034,0,0,0,42.918,0Zm0,16.39a6.426,6.426,0,1,1,6.515-6.425A6.471,6.471,0,0,1,42.918,16.39Z"
            transform="translate(7.37 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_84)">
          <path
            id="Caminho_84-2"
            data-name="Caminho 84"
            d="M75.737,0h-10.1V2.348h0V17.578h0v2.347h9.955c.05,0,.1,0,.148,0a10.035,10.035,0,0,0,10.1-9.965A10.036,10.036,0,0,0,75.737,0Zm0,16.408v0H69.206V3.522h6.532a6.444,6.444,0,1,1,0,12.886Z"
            transform="translate(7.24 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_85)">
          <path
            id="Caminho_85-2"
            data-name="Caminho 85"
            d="M17.792,0,10.085,7.619,2.376,0H0v19.93H3.564V6.156L9.2,11.727h1.766L16.6,6.156V19.932h3.564V0Z"
            transform="translate(7.5 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_86)">
          <path
            id="Caminho_86-2"
            data-name="Caminho 86"
            d="M109.047,0h-1.686l-8.91,19.927h3.9l6.2-13.867,6.2,13.867h3.9L109.745,0Z"
            transform="translate(7.11 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_87)">
          <path
            id="Caminho_87-2"
            data-name="Caminho 87"
            d="M207,0H196.9V2.347h0V17.577h0v2.348h9.955c.05,0,.1,0,.148,0a10.034,10.034,0,0,0,10.1-9.964A10.034,10.034,0,0,0,207,0Zm0,16.407v0h-6.532V3.522h6.533a6.443,6.443,0,1,1,0,12.885Z"
            transform="translate(6.71 2.5)"
            fill="#b9162a"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 47.5, 127.5)" filter="url(#Caminho_88)">
          <path
            id="Caminho_88-2"
            data-name="Caminho 88"
            d="M174.7,0h-1.682l-8.9,19.931h3.894l6.19-13.869,6.19,13.869h3.894L175.4,0Z"
            transform="translate(6.84 2.5)"
            fill="#b9162a"
          />
        </g>
      </g>
    </svg>
  );
}
