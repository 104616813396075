import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { LoaderIcon } from './LoaderIcon';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    maxWidth: '90%',
  },
  overlay: {
    zIndex: 9999,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

if (typeof document !== 'undefined') {
  Modal.setAppElement('#mobile-root');
}

export class ModalHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagem: null,
    };
  }

  close(e) {
    e.preventDefault();
    this.props.onClose();
  }

  success(e) {
    e.preventDefault();
    this.props.success(e, this.props.item);
  }

  lerImagem(imagem) {
    const reader = new FileReader();

    reader.readAsDataURL(imagem);
    reader.onloadend = (data) => {
      this.setState({
        imagem: data.target.result,
      });
    };
  }

  render() {
    const { isOpen, texto, urlImagem, ehImagem, isLoading, success, onCloseText, successText, imagem } = this.props;

    if (imagem) {
      this.lerImagem(imagem);
    }

    return (
      <Modal isOpen={isOpen} onRequestClose={this.close.bind(this)} style={customStyles}>
        <div>
          <h6 className="pt-3">{texto}</h6>
          {isLoading && <LoaderIcon />}

          {ehImagem && <img src={urlImagem} alt="" className="w-100" />}
        </div>

        <div className="d-flex justify-content-end mt-3">
          {onCloseText && (
            <button className="btn btn-sm btn-danger" onClick={this.close.bind(this)}>
              {onCloseText || 'Não'}
            </button>
          )}

          {success && (
            <button className="btn btn-sm btn-success ml-2" onClick={this.success.bind(this)}>
              {successText || 'Sim'}
            </button>
          )}
        </div>
      </Modal>
    );
  }
}

ModalHelper.propTypes = {
  ehImagem: PropTypes.bool,
  imagem: PropTypes.object,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.object,
  onClose: PropTypes.func,
  onCloseText: PropTypes.string,
  success: PropTypes.func,
  successText: PropTypes.string,
  texto: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  urlImagem: PropTypes.string,
};
