import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { SVGModelo } from 'shared/components/SVGModelo';
import { getSVGNodeFromModel } from 'shared/models';
import { trackViewModel } from 'shared/analytics';

import { BonecaVazia } from './BonecaVazia';

export function Manequim({ modo, modelo, variacao, tecido, tecidoUsuario }) {
  useEffect(() => {
    if (!modelo || !modelo._id) {
      return;
    }

    trackViewModel(modelo);
  }, [modelo]);

  return (
    <div className="manequim">
      {modelo ? (
        <SVGModelo
          svg={getSVGNodeFromModel(modelo, variacao)}
          modo={modo}
          tecido={tecido}
          boneca={true}
          tecidoUsuario={tecidoUsuario}
        />
      ) : (
        <BonecaVazia modo={modo} />
      )}
    </div>
  );
}

Manequim.propTypes = {
  modo: PropTypes.string,
  modelo: PropTypes.object,
  variacao: PropTypes.number,
  tecido: PropTypes.object,
  tecidoUsuario: PropTypes.object,
};
