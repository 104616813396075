import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput } from 'react-bootstrap-utils';

import {
  getInvalidMeasuresErrorMessage,
  getTamanhoCinturaInvalidoErrorMessage,
  medidasInvalidas,
  searchTable,
  tamanhoCinturaEhValido,
} from 'shared/measures';
import { noop } from 'shared/helpers/utils';

export function MedidasModeloProva({ order, update, avancar }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = useState('');

  function calcular(inputedMeasures) {
    setState('calculando');

    if (medidasInvalidas(inputedMeasures)) {
      setErrorMessage(getInvalidMeasuresErrorMessage(inputedMeasures));
      setState('erro');

      return;
    }

    searchTable(inputedMeasures).then(
      ({ measure, table }) => {
        if (!measure || !table) {
          return;
        }

        if (!tamanhoCinturaEhValido(inputedMeasures, measure)) {
          setErrorMessage(getTamanhoCinturaInvalidoErrorMessage(measure));
          setState('erro');

          return;
        }

        setState('calculado');
        setErrorMessage('');

        order.setMeasures(inputedMeasures, measure.name);
        order.setupTable(table);
        update(order);
      },
      () => {
        setState('erro');
        setErrorMessage('Ocorreu um erro no cálculo das suas medidas, tente novamente mais tarde.');
      }
    );
  }

  return (
    <div className="passo">
      <div className="content">
        <Form initialValues={{}} onChange={calcular} onSubmit={noop} customActions={<></>}>
          <div className="titulo">
            <p className="text-center my-3 ">Medidas do Tamanho de Prova</p>
          </div>

          <div className="body">
            <div className="form-group row d-flex align-items-center">
              <label className="col-4 col-form-label text-right">Busto</label>
              <div className="col-6 col-sm-4">
                <FormInput
                  type="number"
                  name="bust"
                  className="form-control form-control-lg"
                  min="80"
                  max="134"
                  step="1"
                  required
                />
              </div>
            </div>

            <div className="form-group row d-flex align-items-center">
              <label className="col-4 col-form-label text-right">Cintura</label>
              <div className="col-6 col-sm-4">
                <FormInput type="number" name="waist" className="form-control form-control-lg" step="1" required />
              </div>
            </div>

            <div className="form-group row d-flex align-items-center">
              <label className="col-4 col-form-label text-right">Quadril</label>
              <div className="col-6 col-sm-4">
                <FormInput
                  type="number"
                  name="hip"
                  className="form-control form-control-lg"
                  min="90"
                  max="144"
                  step="1"
                  required
                />
              </div>
            </div>
          </div>

          <div className="feedback">
            {state === 'calculando' ? (
              <div className="calculando">Calculando tamanho da sua modelo...</div>
            ) : state === 'calculado' ? (
              <div className="calculado">
                O tamanho da sua <br />
                modelo de provas é {order.getSizeName()}
              </div>
            ) : state === 'erro' ? (
              <div className="erro-calculo">{errorMessage}</div>
            ) : (
              <div className="aguardando">Insira as medidas acima para prosseguir...</div>
            )}
          </div>
        </Form>
      </div>

      <div className="acao">
        <button
          type="button"
          disabled={state !== 'calculado'}
          onClick={avancar}
          className="btn btn-lg btn-block btn-primary"
        >
          Próximo
        </button>
      </div>
    </div>
  );
}

MedidasModeloProva.propTypes = {
  order: PropTypes.object,
  update: PropTypes.func,
  avancar: PropTypes.func,
};
