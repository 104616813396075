import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SquareBox } from 'shared/components/SquareBox';

import { getTecidosSubgrupoGrupoTecido } from './tecidos-subgrupo-tecido.http';

export function TecidosSubgrupoTecido({ grupo, subgrupo, selecionar }) {
  const tecidosSubgrupo = useMemo(() => getTecidosSubgrupoGrupoTecido(grupo, subgrupo._id));

  return (
    <div className="tecidos-subgrupo-tecido">
      <div className="subgrupo">
        {grupo.name} &gt; {subgrupo.name}
      </div>
      <div className="tecidos">
        {tecidosSubgrupo.length === 0 ? (
          <div className="my-3">Nenhum tecido disponível</div>
        ) : (
          tecidosSubgrupo.map((tecido) => (
            <div key={tecido._id} className="tecido" onClick={() => selecionar(tecido)}>
              <SquareBox>
                <img src={tecido.images.thumbnail.url} alt={tecido.name} width="150px" crossOrigin="anonymous" />
              </SquareBox>
              <div className="label">{tecido.name}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

TecidosSubgrupoTecido.propTypes = {
  grupo: PropTypes.object,
  subgrupo: PropTypes.object,
  selecionar: PropTypes.func,
};
