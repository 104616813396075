import { useState } from 'react';
import { useParams } from 'react-router';
import { useToasts } from 'react-bootstrap-utils';
import { Link } from 'react-router-dom';

import { formatDate, formatMoney, formatAddress } from 'shared/helpers/formatters';
import { Icon } from 'shared/components/Icon';
import { LoaderIcon } from 'shared/components/LoaderIcon';
//FIXME: replace ModelHelper with react-bootstrap-utils
import { ModalHelper } from 'shared/components/ModalHelper';
import { useOrder } from 'shared/http/orders.http';
import { startPagseguroPayment } from 'shared/http/cart-payment.http';

const statusList = {
  nova: 'Aguardando confirmação de pagamento',
  paga: 'Pagamento confirmado',
  'em produção': 'Em produção',
  enviada: 'Enviado',
  entregue: 'Entregue',
  concluída: 'Finalizado',
  cancelada: 'Cancelado',
};

export function OrderPage() {
  const { showToast } = useToasts();
  const { pedidoId } = useParams();
  const { order, isLoading } = useOrder(pedidoId);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function pagar(e) {
    e.preventDefault();

    setModalIsOpen(true);

    startPagseguroPayment(order)
      .then((url) => {
        closeModal();
        window.location.assign(url);
      })
      .catch((err) => {
        closeModal();

        showToast(err.message, {
          type: 'danger',
        });
      });
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  if (!order) {
    return (
      <div className="order-page">
        <em>Pedido não encontrado</em>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="order-page">
        <LoaderIcon />
      </div>
    );
  }

  const { address, name } = { address: null, name: null };

  return (
    <div className="order-page">
      <div className="menu d-flex">
        <Link to="/meus-pedidos">
          <Icon icon="left-arrow" />
        </Link>

        <div className="titulo">Detalhes do Pedido</div>
        <span />
      </div>

      <div className="conteudo container-fluid">
        <div className="listagem">
          <div className="item">
            <p>
              <strong>Status Atual: </strong>
              {statusList[order.status]}
            </p>

            <p>
              <strong>Criado em: </strong>
              {formatDate(order.createdAt)}
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <h5 className="text-light">Produtos</h5>
          <h5 className="text-light pr-1">{formatMoney(order.subtotal)}</h5>
        </div>

        <div className="listagem">
          {order.items
            ? order.items.map((item) => (
                <div key={item._id} className="item">
                  <div className="d-flex">
                    <div className="ml-3 descricao">
                      <div className="d-flex justify-content-between">
                        <span>{item.code}</span>
                        <span>
                          <strong>{formatMoney(item.total)}</strong>
                        </span>
                      </div>

                      <div className="d-flex justify-content-between mt-2">
                        <small>{item.sizes.map((s) => `${s.name} (${s.size})`).join(', ')}</small>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>

        {order.cupomDesconto && (
          <div className="d-flex justify-content-between mt-3">
            <h5 className="text-light">Desconto</h5>
            <h5 className="text-light pr-1">{formatMoney(order.valorDesconto)}</h5>
          </div>
        )}

        <div className="listagem">
          <div className="item">
            {address ? (
              <div>
                <div className="d-flex justify-content-between">
                  <strong>{name} </strong>
                </div>
                <p>{formatAddress(address)}</p>
              </div>
            ) : (
              <p>Retirada no local</p>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3 mb-4">
          <h5 className="text-light">Total:</h5>
          <h5 className="text-light pr-1">{formatMoney(order.total)}</h5>
        </div>

        {order.status === 'nova' ? (
          <div className="text-light">
            <a href="" onClick={pagar}>
              <img src="/assets/botao-pagseguro.png" alt="Pagar com Pagseguro" className="botao-pagseguro" />
            </a>

            <p>
              *Esse botão continuará disponível até que o processamento do pagamento seja completado, caso já tenha
              efetuado o pagamento, aguarde.
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
      <ModalHelper
        isOpen={modalIsOpen}
        texto={'Aguarde enquanto processamos seu pagamento.'}
        onClose={closeModal}
        isLoading={true}
      />
    </div>
  );
}
