import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useConfirmationDialog } from 'react-bootstrap-utils';

import { formatMoney } from 'shared/helpers/formatters';
import { Icon } from 'shared/components/Icon';
import { trackRemoveFromCart } from 'shared/analytics';

import { RenderModel } from '../../desktop/catalog/catwalk/RenderModel';

export function ShoppingCartItem({ item, removeItem }) {
  const variationIndex = useMemo(() => parseInt(item.modelVariation, 10), [item.modelVariation]);

  const { DialogPortal, showDialog } = useConfirmationDialog({
    title: 'Atenção',
    message() {
      return <em>Deseja excluir esse item do carrinho?</em>;
    },
    onProceed: () => {
      trackRemoveFromCart(item);

      return removeItem(item);
    },
  });

  function openRemoveFromCartDialog(e) {
    e.preventDefault();
    showDialog();
  }

  return (
    <div className="d-flex">
      <DialogPortal />
      <RenderModel
        model={item.model}
        fabric={item.fabricToken}
        userFabric={item.userFabric}
        variationIndex={variationIndex}
        frontMode
        showDoll={false}
      />

      <div className="ml-3 description">
        <div className="d-flex justify-content-between">
          <span>{item.code}</span>
          <span>
            <strong>{formatMoney(item.total)}</strong>
          </span>
        </div>

        <div className="d-flex justify-content-between mt-2">
          <small>{item.sizes.map((s) => `${s.name} (${s.size})`).join(', ')}</small>

          <span className="actions">
            <Link to={`/carrinho-compras/${item._id}`} title="Editar">
              <Icon icon="edit" />
            </Link>

            <a href="" className="mx-2" title="Remover" onClick={openRemoveFromCartDialog}>
              <Icon icon="trash" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
ShoppingCartItem.propTypes = {
  item: PropTypes.object,
  removeItem: PropTypes.func,
};
