import { useEffect, useRef } from 'react';

export function SVGCurva() {
  const svgRef = useRef();

  function trimSVG() {
    const svgElem = svgRef.current;

    const dimensoes = svgElem.getBBox();

    svgElem.setAttribute('width', window.outerWidth);
    svgElem.setAttribute('height', (window.outerWidth / dimensoes.width) * dimensoes.height);
    svgElem.viewBox.baseVal.width = window.outerWidth;
    svgElem.viewBox.baseVal.height = (window.outerWidth / dimensoes.width) * dimensoes.height;
    svgElem.children[0].setAttribute('transform', `scale(${window.outerWidth / dimensoes.width})`);
  }

  useEffect(() => {
    trimSVG();
  }, []);

  return (
    <svg
      className="modelo-svg"
      ref={svgRef}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 0 0"
    >
      <path d={'M0,0 Q180,76 360,0'} fill="#fff" />
    </svg>
  );
}
