import { useCartPayment } from 'shared/useCartPayment';
import { getWhatsappLink } from 'shared/components/WhatsAppBtn';

export function Sucesso() {
  const { getWhatsappMessage } = useCartPayment();

  const whatsappMessage = getWhatsappMessage();

  return (
    <div className="passo">
      <div className="container-fluid conteudo" style={{color: '#ddd', textAlign: 'center'}}>
        <h2 style={{color: '#fff'}}>Pedido concluído com sucesso!</h2>
        <p />
        <p>
          Em alguns instantes você irá receber um contato por email com os dados para pagamento.
        </p>

        <p>
          Você também pode falar diretamente conosco via whatsapp clicando <a href={getWhatsappLink(whatsappMessage)} style={{textDecoration:'underline'}} target="_blank" rel="noreferrer">aqui</a>
        </p>

      </div>

    </div>
  );
}
