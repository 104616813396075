import { RecoverPassword } from 'shared/auth/RecoverPassword';

import { Topo } from '../components/Topo';

export function RecoverPage() {
  return (
    <div className="login-page-step">
      <div className="container-fluid login-page recover-page">
        <Topo textos={['Recuperar senha']} topoRegistro={false} />
        <div className="formulario">
          <div className="check-user-email-form login-page-step">
            <RecoverPassword showTitle={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
