import { useState } from 'react';
import { useAuthStates } from 'shared/auth/auth-state-machine';
import { useRedirectIfAuthenticated } from 'shared/auth/useRedirectIfAuthenticated';

import { Topo } from '../components/Topo';

export function LoginPage() {
  const [topTitle, setTopTitle] = useState([]);
  const [topRegister, setTopRegister] = useState(false); //wtf
  const { Component, data, onGoBack, onProceed } = useAuthStates({
    initialState: 'email',
    onGoBackSideEffect,
    onProceedSideEffect,
  });

  useRedirectIfAuthenticated();

  function onGoBackSideEffect() {
    setTopTitle(['']);
    setTopRegister(false);
  }
  function onProceedSideEffect(state) {
    switch (state) {
      case 'password':
        setTopTitle(['Bem-vindo de volta!']);
        setTopRegister(false);
        break;

      case 'register':
        setTopTitle(['']);
        setTopRegister(true);
        break;

      case 'recover':
        setTopTitle(['Recuperar senha']);
        break;

      default:
        break;
    }
  }

  return (
    <div className="container-fluid login-page">
      <Topo textos={topTitle} topoRegistro={topRegister} />
      <div className="formulario">
        <Component onProceed={onProceed} onGoBack={onGoBack} data={data} />
      </div>
    </div>
  );
}
