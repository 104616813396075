import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getSubgruposGrupoTecidoByModelo } from './subgrupos-tecido.http';

export function SubgruposTecido({ grupo, modeloSelecionado, selecionar }) {
  const subgrupos = useMemo(
    () => getSubgruposGrupoTecidoByModelo(grupo, modeloSelecionado),
    [grupo, modeloSelecionado]
  );

  return (
    <div className="subgrupos-tecido">
      <div className="list-group">
        <div className="grupo list-group-item">{grupo.name}</div>

        {subgrupos.map((subgrupo) => (
          <div key={subgrupo._id} className="subgrupo-tecido list-group-item" onClick={() => selecionar(subgrupo)}>
            {subgrupo.name}
          </div>
        ))}
      </div>
    </div>
  );
}

SubgruposTecido.propTypes = {
  grupo: PropTypes.object,
  modeloSelecionado: PropTypes.object,
  selecionar: PropTypes.func,
};
