import { useMemo } from 'react';

import { Icon } from 'shared/components/Icon';
import { useModel } from 'shared/http/models.http';
import { useSearch } from 'shared/helpers/useSearch';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useFabricGroup } from 'shared/http/fabric-groups.http';

import { getSubgrupoGrupoTecido } from './subgrupos/subgrupos-tecido.http';
import { GruposTecido } from './grupos/GruposTecido';
import { SubgruposTecido } from './subgrupos/SubgruposTecido';
import { TecidosSubgrupoTecido } from './tecidos-subgrupo/TecidosSubgrupoTecido';
import { AmostrasTecido } from './amostras/AmostrasTecido';

export function TecidosPage() {
  const urlQuery = useSearch();
  const { navigate, updateSearch } = useNavigate();

  const { model: modeloSelecionado } = useModel(urlQuery.modelo);

  const grupoSelecionadoId = useMemo(() => urlQuery['grupo-tecido'], [urlQuery]);
  const subgrupoSelecionadoId = useMemo(() => urlQuery['subgrupo-tecido'], [urlQuery]);
  const tecidoSelecionadoId = useMemo(() => urlQuery.tecido, [urlQuery]);

  const { fabricGroup: grupoSelecionado } = useFabricGroup(grupoSelecionadoId);

  const subgrupoSelecionado = useMemo(
    () => getSubgrupoGrupoTecido(grupoSelecionado, subgrupoSelecionadoId),
    [grupoSelecionado, subgrupoSelecionadoId]
  );

  const tecidoSelecionado = useMemo(() => {
    if (!subgrupoSelecionado || !tecidoSelecionadoId) {
      return;
    }

    return subgrupoSelecionado.fabrics.find(({ _id }) => _id === tecidoSelecionadoId);
  }, [subgrupoSelecionado, tecidoSelecionadoId]);

  function selecionarTecido(tecido) {
    updateSearch({
      tecido: tecido._id,
    });
  }

  function selecionarSubgrupo(subgrupo) {
    updateSearch({
      'subgrupo-tecido': subgrupo._id,
    });
  }

  function selecionarGrupo(grupo) {
    updateSearch({
      'grupo-tecido': grupo._id,
    });
  }

  function irParaMeusTecidos(e) {
    e.preventDefault();

    navigate(
      '/meus-tecidos',
      {},
      {
        inherit: true,
      }
    );
  }

  function uploadTecido(e) {
    e.preventDefault();

    navigate(
      '/upload-tecido',
      {},
      {
        inherit: true,
      }
    );
  }

  const voltar = (e) => {
    e.preventDefault();

    //FIXME: remove redundant elseif with eslint
    if (urlQuery['amostra-tecido']) {
      const { 'amostra-tecido': amostra, tecido, ...resto } = urlQuery;

      return navigate('/tecidos', resto);
    } else if (urlQuery.tecido) {
      const { tecido, ...resto } = urlQuery;

      return navigate('/tecidos', resto);
    } else if (urlQuery['subgrupo-tecido']) {
      const { 'subgrupo-tecido': subgrupo, ...resto } = urlQuery;

      return navigate('/tecidos', resto);
    }

    const { 'grupo-tecido': grupo, ...resto } = urlQuery;

    if (urlQuery['grupo-tecido']) {
      return navigate('/tecidos', resto);
    }

    return navigate('/', resto);
  };

  return (
    <div className="tecidos-page">
      <div className="menu">
        <div className="d-flex justify-content-between">
          <a href="" onClick={voltar}>
            <Icon icon="left-arrow" />
          </a>

          <span>Escolha um tecido</span>

          <a href="">
            <Icon icon="search" />
          </a>
        </div>
      </div>

      <div className="catalogo">
        {tecidoSelecionado ? (
          <AmostrasTecido tecido={tecidoSelecionado} />
        ) : subgrupoSelecionado ? (
          <TecidosSubgrupoTecido
            grupo={grupoSelecionado}
            subgrupo={subgrupoSelecionado}
            selecionar={selecionarTecido}
          />
        ) : grupoSelecionado ? (
          <SubgruposTecido
            grupo={grupoSelecionado}
            modeloSelecionado={modeloSelecionado}
            selecionar={selecionarSubgrupo}
          />
        ) : (
          <GruposTecido selecionar={selecionarGrupo} meusTecidos={irParaMeusTecidos} uploadTecido={uploadTecido} />
        )}
      </div>
    </div>
  );
}
