import PropTypes from 'prop-types';

export function SquareBox({ children }) {
  return (
    <div
      style={{
        width: '100%',
        height: '0',
        paddingBottom: '100%',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>{children}</div>
    </div>
  );
}

SquareBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
