import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Form } from 'react-bootstrap-utils';

import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useNavigate } from 'shared/helpers/useNavigate';
import { saveBillingAddress, useBillingAddress } from 'shared/http/billing-address.http';
import {
  BillingAddressFormFields,
  billingAddressFormValidations,
  fromAdressApi,
  ShippingAddressFormField,
  toAdressApi,
} from 'shared/address/AddressFormFields';

import { TopoPedido } from '../components/TopoPedido';

const passos = ['dadosFaturamento', 'endereco'];

export function EditAddressPage() {
  const { navigate, navigateBack } = useNavigate();
  const { enderecoId } = useParams();
  const { billingAddress, isLoading } = useBillingAddress(enderecoId);
  const endereco = useMemo(() => fromAdressApi(billingAddress), [billingAddress]);

  const [passoAtual, setPassoAtual] = useState(0);
  const [enderecoAtualizado, setEnderecoAtualizado] = useState({});

  function salvarEndereco(changedAddress) {
    saveBillingAddress(enderecoId, toAdressApi(changedAddress)).then(() => {
      navigate('/carrinho-compras/pagamento');
    });
  }

  function avancarProximoPasso(dadosEndereco) {
    if (passoAtual >= passos.length) {
      return;
    }

    setPassoAtual((step) => step + 1);
    setEnderecoAtualizado((prevState) => ({
      ...prevState,
      ...dadosEndereco,
    }));
  }

  function voltarPassoAnterior(e) {
    e.preventDefault();

    if (passoAtual === 0) {
      navigateBack();

      return;
    }

    setPassoAtual((step) => step - 1);
  }

  function isActive(step) {
    return passos[passoAtual] === step;
  }

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="editar-endereco-page">
      <TopoPedido textos={['Dados para faturamento']} goBack={voltarPassoAnterior} />

      <div className="container-fluid">
        {isActive('dadosFaturamento') ? (
          <div className="dados-faturamento">
            <Form
              initialValues={endereco}
              onSubmit={avancarProximoPasso}
              validations={billingAddressFormValidations}
              customActions={
                <div className="acao">
                  <button type="submit" className="btn btn-lg btn-block btn-primary">
                    Próximo
                  </button>
                </div>
              }
            >
              <BillingAddressFormFields />
            </Form>
          </div>
        ) : isActive('endereco') ? (
          <div className="dados-faturamento">
            <Form
              initialValues={enderecoAtualizado}
              onSubmit={salvarEndereco}
              customActions={
                <div className="acao">
                  <button type="submit" className="btn btn-lg btn-block btn-primary">
                    Próximo
                  </button>
                </div>
              }
            >
              <ShippingAddressFormField />
            </Form>
          </div>
        ) : (
          'Erro'
        )}
      </div>
    </div>
  );
}
