import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';
import { useModel } from 'shared/http/models.http';
import { useSearch } from 'shared/helpers/useSearch';
import { useSession } from 'shared/http/auth.http';
import {
  disableFilteredGroups,
  getFabricGroupNameWithoutType,
  useFabricGroupsClusteredByTypes,
} from 'shared/http/fabric-groups.http';

export function GruposTecido({ selecionar, meusTecidos, uploadTecido }) {
  const { modelo: modeloId } = useSearch();
  const { model: modeloSelecionado } = useModel(modeloId);

  const { isAuthenticated } = useSession();

  const { fabricGroupsByType } = useFabricGroupsClusteredByTypes();
  const filteredFabricsGroupsByType = useMemo(() => {
    if (modeloSelecionado) {
      const filter = [...modeloSelecionado.group_ids];

      //FIXME: make it immutable
      disableFilteredGroups(fabricGroupsByType, filter);
    }

    return fabricGroupsByType;
  }, [fabricGroupsByType, modeloSelecionado]);

  return (
    <div className="grupos-tecido">
      {isAuthenticated && (
        <div key="Meus tecidos" className="list-group">
          <div className="tipo list-group-item" onClick={meusTecidos}>
            Meus Tecidos
          </div>
        </div>
      )}
      {Object.entries(filteredFabricsGroupsByType).map(([tipo, grupos]) => (
        <div key={tipo} className="list-group">
          <div className="tipo list-group-item">{tipo}</div>
          {grupos.map((grupoTecido) => (
            <div
              key={grupoTecido._id}
              className={`grupo-tecido list-group-item${grupoTecido.disabled ? ' disabled' : ''}`}
              onClick={() => selecionar(grupoTecido)}
            >
              {getFabricGroupNameWithoutType(grupoTecido)}
            </div>
          ))}
        </div>
      ))}
      <div className="btn-upload-tecido">
        <a className="btn btn-primary" onClick={uploadTecido}>
          <Icon icon="camera" />
        </a>
      </div>
    </div>
  );
}

GruposTecido.propTypes = {
  selecionar: PropTypes.func,
  meusTecidos: PropTypes.func,
  uploadTecido: PropTypes.func,
};
