export const getSubgrupoGrupoTecido = (grupo, subgrupoId) => {
  const subgrupos = grupo ? grupo.subgroups : [];

  return subgrupos.find((s) => s._id === subgrupoId);
};

export const getSubgruposGrupoTecidoByModelo = (grupo, modelo) => {
  if (!grupo) {
    return [];
  }

  if (!modelo) {
    return grupo ? grupo.subgroups : [];
  }

  const subgrupos = modelo.subgroup_ids;

  if (!subgrupos || subgrupos.length === 0) {
    return [];
  }

  return grupo.subgroups.filter((subgrupo) => subgrupos.includes(subgrupo._id));
};
