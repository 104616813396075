import { getSubgrupoGrupoTecido } from '../subgrupos/subgrupos-tecido.http';

export const getTecidosSubgrupoGrupoTecido = (grupo, subgrupoId) => {
  const subgrupo = getSubgrupoGrupoTecido(grupo, subgrupoId);

  if (!subgrupo) {
    return [];
  }

  return subgrupo.fabrics.filter((f) => Boolean(f.images));
};

export const getTecidoSubgrupoGrupoTecido = (state, grupo, subgrupoId, tecidoId) => {
  const tecidos = getTecidosSubgrupoGrupoTecido(grupo, subgrupoId, tecidoId);

  return tecidos.find((s) => s._id === tecidoId);
};
