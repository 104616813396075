import { useCartPayment } from 'shared/useCartPayment';
import { CartPaymentProvider } from 'shared/CartPaymentContext';
import { useNavigate } from 'shared/helpers/useNavigate';

import { TopoPedido } from '../components/TopoPedido';

import { SelecionarEndereco } from './StepSelectAddress';
import { ResumoPedido } from './StepSummary';
import { AceitacaoContratoVenda } from './StepAcceptContract';
import { Sucesso } from './Sucesso';

export function CartPaymentPage() {
  const { navigateBack } = useNavigate();

  return (
    <CartPaymentProvider onEmptyCart={navigateBack}>
      <CompraSteps />
    </CartPaymentProvider>
  );
}

function CompraSteps() {
  const { navigateBack } = useNavigate();

  const { stepCart, backStep } = useCartPayment();
  const stepTitles = ['Endereço', 'Confirme seu pedido', 'Contrato', 'Sucesso'];

  function voltarPassoAnterior(e) {
    e.preventDefault();

    if (stepCart > 0) {
      backStep();
    } else {
      navigateBack();
    }
  }

  return (
    <div className="novo-pedido-page">
      <TopoPedido textos={[stepTitles[stepCart]]} goBack={voltarPassoAnterior} />

      {stepCart === 0 ? (
        <SelecionarEndereco />
      ) : stepCart === 1 ? (
        <ResumoPedido />
      ) : stepCart === 2 ? (
        <AceitacaoContratoVenda />
      ) : stepCart === 3 ? (
        <Sucesso />
      ) : (
        ''
      )}
    </div>
  );
}

