import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Icon } from 'shared/components/Icon';
import { editShoppingCartItem, useShoppingCart } from 'shared/http/shopping-cart.http';
import { MoldOrder } from 'shared/MoldOrder';
import { useNavigate } from 'shared/helpers/useNavigate';
import { usePreferences } from 'shared/http/preferences.http';
import { LoaderIcon } from 'shared/components/LoaderIcon';

import { SelecaoTamanhosModelo } from '../compra/molde/SelecaoTamanhosModelo';

export function EditarItemPage() {
  const { itemId } = useParams();
  const { shoppingCart } = useShoppingCart();

  const item = useMemo(() => shoppingCart?.items?.find(({ _id }) => _id === itemId), [shoppingCart, itemId]);

  const [order, setOrder] = useState();
  const { preferences, isLoading: isLoadingPreferences } = usePreferences();
  const isLoading = isLoadingPreferences;
  const { navigate } = useNavigate();

  function atualizar(_order) {
    const sizes = _order.getSizes();

    _order.setSizes(sizes);

    setOrder(_order);
  }

  function salvar() {
    editShoppingCartItem(order._order).then(() => {
      goBack();
    });
  }

  useEffect(() => {
    if (isLoading || !item) {
      return;
    }

    MoldOrder.initFromOrder(item, preferences).then((order) => {
      setOrder(order);
    });
  }, [isLoading, item]);

  const goBack = (e) => {
    if (e) {
      e.preventDefault();
    }

    navigate('/carrinho-compras');
  };

  if (isLoading || !order) {
    return <LoaderIcon />;
  }

  return (
    <div className="compra-molde-page">
      <div className="d-flex justify-content-between mt-3 ml-3">
        <a href="" onClick={goBack}>
          <Icon icon="left-arrow" />
        </a>

        <span />
      </div>

      <SelecaoTamanhosModelo order={order} update={atualizar} avancar={salvar} />
    </div>
  );
}
