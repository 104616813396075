import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SquareBox } from 'shared/components/SquareBox';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useNavigate } from 'shared/helpers/useNavigate';
import { getExtraInfoFromFabricToken, useFabricTokens } from 'shared/http/fabric-tokens.http';
import { useSearch } from 'shared/helpers/useSearch';

export function AmostrasTecido({ tecido }) {
  const { tecido: tecidoSelecionadoId } = useSearch();
  const { navigate } = useNavigate();

  const { fabricTokens: _fabricTokens, isLoading } = useFabricTokens(
    {
      fabricId: tecidoSelecionadoId,
    },
    Boolean(tecidoSelecionadoId)
  );
  const amostras = useMemo(
    () =>
      _fabricTokens.map((fabricToken) => ({
        ...fabricToken,
        extras: getExtraInfoFromFabricToken(fabricToken),
      })),
    [_fabricTokens]
  );

  const selecionar = (amostra) => {
    navigate(
      '/',
      {
        'amostra-tecido': amostra._id,
      },
      { inherit: true }
    );
  };

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="amostras-tecido">
      <div className="tecido">{tecido.name}</div>
      <div className="amostras">
        {amostras.map((amostra) => (
          <div key={amostra._id} className="amostra" onClick={() => selecionar(amostra)}>
            <SquareBox>
              <img src={amostra.images.thumbnail.url} alt={amostra.name} width="150px" crossOrigin="anonymous" />
            </SquareBox>
            <div className="label">{amostra.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

AmostrasTecido.propTypes = {
  tecido: PropTypes.object.isRequired,
};
