import { useState } from 'react';

import { useSession } from 'shared/http/auth.http';
import { Icon } from 'shared/components/Icon';
import { SidebarOpener } from 'shared/components/SidebarOpener';
import { useModelType } from 'shared/http/model-types.http';
import { useModel } from 'shared/http/models.http';
import { useSearch } from 'shared/helpers/useSearch';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useFabricToken } from 'shared/http/fabric-tokens.http';
import { useUserFabric } from 'shared/http/user-fabrics.http';
import { getVariationIndexByCode } from 'shared/models';
import { useInviteIfNotLogged } from 'shared/auth/AccountInvite';
import { trackSelectModel } from 'shared/analytics';

import { TiposModelo } from './models/TiposModelo';
import { ModeloSelecionado } from './models/ModeloSelecionado';
import { ModelosByTipo } from './models/ModelosByTipo';
import { Manequim } from './Manequim';

export function CatalogoPage() {
  const { isAuthenticated } = useSession();
  const { inviteIfNotLogged, InviteIfNotLoggedPortal } = useInviteIfNotLogged({ mobile: true });
  const [state, setState] = useState({
    modo: 'frente',
    qtModelosSelecionados: 0,
  });

  const {
    'tipo-modelo': tipoModeloId,
    modelo: modeloId,
    'amostra-tecido': amostraId,
    'tecido-usuario': tecidoUsuarioId,
    variacao: selectedVariation,
  } = useSearch();

  const { modelType: tipoModeloSelecionado } = useModelType(tipoModeloId);
  const { model: modeloSelecionado } = useModel(modeloId);
  const { fabricToken: amostraTecidoSelecionada } = useFabricToken(amostraId);
  const { userFabric: tecidoUsuario } = useUserFabric(tecidoUsuarioId);
  const variationIndex = getVariationIndexByCode(modeloSelecionado, selectedVariation);

  const { navigate, updateSearch } = useNavigate();

  function virarModelo(e) {
    e.preventDefault();

    setState({
      ...state,
      modo: state.modo === 'frente' ? 'costas' : 'frente',
    });
  }

  function selecionarTipo(tipo) {
    updateSearch({
      'tipo-modelo': tipo._id,
    });
  }

  function selecionarModelo(modelo) {
    trackSelectModel(modelo);

    if (!isAuthenticated) {
      if (state.qtModelosSelecionados >= 10) {
        inviteIfNotLogged();
      }

      setState((prevState) => ({ ...prevState, qtModelosSelecionados: prevState.qtModelosSelecionados + 1 }));
    }

    updateSearch({
      modelo: modelo._id,
      variacao: undefined,
    });
  }

  async function uploadTecido(e) {
    e.preventDefault();
    e.stopPropagation();

    const convidado = await inviteIfNotLogged();

    if (convidado) {
      return;
    }

    navigate('/upload-tecido', {}, { inherit: true });
  }

  const abrirTiposModelo = () => {
    updateSearch({
      modelo: null,
      'tipo-modelo': null,
    });
  };

  const abrirCatalogoTecidos = () => {
    navigate('/tecidos', {}, { inherit: true });
  };

  const comprarMolde = () => {
    navigate('/compra-molde', {}, { inherit: true });
  };

  const removerAmostraSelecionada = (e) => {
    e.preventDefault();
    e.stopPropagation();

    updateSearch({
      'amostra-tecido': undefined,
      'subgrupo-tecido': undefined,
      'grupo-tecido': undefined,
      tecido: undefined,
    });
  };

  const removerTecidoUsuario = (e) => {
    e.preventDefault();
    e.stopPropagation();

    updateSearch({
      'tecido-usuario': undefined,
    });
  };

  return (
    <div className="catalogo-page">
      <InviteIfNotLoggedPortal />

      <div className="navegacao">
        <div className={`passarela ${modeloSelecionado ? 'permite-compra' : ''}`}>
          <SidebarOpener />

          <Manequim
            modo={state.modo}
            modelo={modeloSelecionado}
            variacao={variationIndex}
            tecido={amostraTecidoSelecionada}
            tecidoUsuario={tecidoUsuario}
          />

          <div className="virar-modelo">
            <a href="" onClick={virarModelo}>
              <Icon icon="rotate" />
            </a>
          </div>

          <div className="btn-upload-tecido">
            <a href="" onClick={uploadTecido} className="btn btn-primary">
              <Icon icon="camera" />
            </a>
          </div>
        </div>

        <div className="catalogo">
          <ModeloSelecionado
            modelo={modeloSelecionado}
            tecido={amostraTecidoSelecionada}
            variacao={selectedVariation}
            abrirCatalogoTecidos={abrirCatalogoTecidos}
            removerAmostra={removerAmostraSelecionada}
            tecidoUsuario={tecidoUsuario}
            removerTecidoUsuario={removerTecidoUsuario}
          >
            {modeloSelecionado ? (
              <div className="compra">
                <button className="btn btn-lg btn-block btn-primary" onClick={comprarMolde}>
                  Pedir Molde
                </button>
              </div>
            ) : (
              ''
            )}
          </ModeloSelecionado>

          {!modeloSelecionado && (
            <>
              {tipoModeloSelecionado ? (
                <ModelosByTipo
                  tipo={tipoModeloSelecionado}
                  voltar={abrirTiposModelo}
                  selecionarModelo={selecionarModelo}
                  tecido={amostraTecidoSelecionada}
                  tecidoUsuario={tecidoUsuario}
                />
              ) : (
                <TiposModelo
                  selecionarTipo={selecionarTipo}
                  tecido={amostraTecidoSelecionada}
                  tecidoUsuario={tecidoUsuario}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
