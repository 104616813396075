import { Link } from 'react-router-dom';

export function SucessoCompraMolde() {
  return (
    <div className="passo sucesso-compra-molde">
      <div className="content">
        <div>
          <h2 className="mensagem-sucesso">
            Seu pedido <br /> foi enviado para <br />o carrinho com sucesso
          </h2>
        </div>
        <div className="d-flex flex-column">
          <Link to="/" className="mensagem-sucesso mb-5">
            Escolher mais modelos
          </Link>

          <Link to="/carrinho-compras" className="mensagem-sucesso">
            Ver meu carrinho
          </Link>
        </div>
      </div>
    </div>
  );
}
