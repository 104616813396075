import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { formatAddress } from 'shared/helpers/formatters';
import { Icon } from 'shared/components/Icon';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useCartPayment } from 'shared/useCartPayment';
import { useBillingAddresses } from 'shared/http/billing-address.http';

export function SelecionarEndereco() {
  const { selectedAddress: selectedAddressId, setSelectedAddress, setBillingAddress, proximo } = useCartPayment();

  const { billingAddresses: enderecos, isLoading } = useBillingAddresses();

  function onSelect(address) {
    setSelectedAddress(address._id);
    setBillingAddress(address);
  }

  useEffect(() => {
    if (!enderecos || enderecos.length === 0) {
      return;
    }

    onSelect(enderecos[0]);
  }, [enderecos]);

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="passo">
      <div className="container-fluid conteudo selecionar-endereco">
        <div className="enderecos">
          {enderecos.map((endereco) => (
            <div
              key={endereco._id}
              className={`d-flex flex-row align-items-center mt-3 listagem ${
                selectedAddressId === endereco._id ? 'endereco-escolhido' : ''
              }`}
              onClick={() => onSelect(endereco)}
            >
              <div>
                <div className="d-flex item primeira-linha">
                  {selectedAddressId === endereco._id ? <Icon icon="checked" /> : <Icon icon="unchecked" />}{' '}
                  <strong>{endereco.name}</strong>
                </div>
                <p className="mt-2 pl-1 pr-1">{formatAddress(endereco.address)}</p>
                <div className="icones">
                  <Link to={`/enderecos/${endereco._id}`} title="Editar">
                    <Icon icon="edit" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center my-3">
          <Link to="/enderecos/novo">
            <p className="adicionar-endereco">Novo endereço</p>
          </Link>
        </div>
      </div>

      <div className="acao">
        <button className="btn btn-lg btn-block btn-primary" onClick={proximo} disabled={!selectedAddressId}>
          Próximo
        </button>
      </div>
    </div>
  );
}
