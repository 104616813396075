import { Link } from 'react-router-dom';

import { formatMoney } from 'shared/helpers/formatters';
import { FormDesconto } from 'shared/components/FormDesconto';
import { Icon } from 'shared/components/Icon';
import { stringify } from 'shared/helpers/query-string';
import { useCartPayment } from 'shared/useCartPayment';
import { useCoupom } from 'shared/http/cupom-desconto.http';

export function ResumoPedido() {
  const { itens, cart, cupomText, setCupomText, proximo } = useCartPayment();
  const { coupom, isLoading, isError } = useCoupom(cupomText);

  const subtotal = cart.total;
  const desconto = subtotal * (coupom ? coupom.percentualDesconto : 0);
  const total = subtotal - desconto;

  return (
    <div className="passo">
      <div className="conteudo container-fluid resumo-pedidos">
        <div className="listagem listagem-produtos">
          {itens.map((item) => (
            <div key={item._id} className="item">
              <div className="d-flex">
                <div className="ml-3 descricao">
                  <div className="d-flex justify-content-between">
                    <span>{item.code}</span>
                    <span>
                      <strong>{formatMoney(item.total)}</strong>
                    </span>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <small>{item.sizes.map((s) => `${s.name} (${s.size})`).join(', ')}</small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="editar-pedido">
          <div className="mc-icon">
            <Icon icon="arrow" />
          </div>
          <Link to={{ pathname: '/carrinho-compras', search: stringify({ backToResumo: true }) }}>
            <h6 className="text-light text-center">Alterar pedido</h6>
          </Link>
        </div>

        <div className="d-flex justify-content-right mt-1">
          <h6 className="text-light total">
            <span className="font-weight-light">Subtotal:</span> {formatMoney(subtotal)}
          </h6>
        </div>

        <FormDesconto
          coupom={coupom}
          isLoading={isLoading}
          isError={isError}
          desconto={desconto}
          onChange={(codigo) => {
            setCupomText(codigo);
          }}
        />

        <div className="d-flex justify-content-right mt-1">
          <h6 className="text-light total">
            <span className="font-weight-light">Total:</span> {formatMoney(total)}
          </h6>
        </div>
      </div>

      <div className="acao">
        <button type="button" className="btn btn-lg btn-block btn-primary" onClick={proximo}>
          Próximo
        </button>
      </div>
    </div>
  );
}
