import { Route, Switch } from 'react-router-dom';
import { ToastsContainer } from 'react-bootstrap-utils';

import { Sidebar } from 'shared/components/Sidebar';
import { NotFoundPage } from 'shared/components/NotFoundPage';

import { CarrinhoComprasPage } from './shopping-cart/ShoppingCartPage';
import { CatalogoPage } from './catalog/CatalogPage';
import { CompraMoldePage } from './compra/molde/CompraMoldePage';
import { EditAddressPage } from './address/EditAddressPage';
import { EditarItemPage } from './shopping-cart/EditarItemPage';
import { LoginPage } from './auth/LoginPage';
import { CartPaymentPage } from './payment/CartPaymentPage';
import { OrderPage } from './orders/OrderPage';
import { OrdersPage } from './orders/OrdersPage';
import { TecidosPage } from './catalog/tecidos/TecidosPage';
import { TecidosUsuarioPage } from './catalog/tecidos-usuario/TecidosUsuarioPage';
import { UploadTecidoUsuarioPage } from './catalog/tecidos-usuario/UploadTecidoUsuarioPage';
import { RecoverPage } from './auth/RecoverPage';

export const MobileApp = () => (
  <div style={{ position: 'relative' }} className="full-height">
    <ToastsContainer>
      <Sidebar />
      <Switch>
        <Route path="/carrinho-compras/pagamento" component={CartPaymentPage} />
        <Route path="/carrinho-compras/:itemId" component={EditarItemPage} />
        <Route path="/carrinho-compras" component={CarrinhoComprasPage} />
        <Route path="/compra-molde" component={CompraMoldePage} />
        <Route path="/enderecos/novo" component={EditAddressPage} />
        <Route path="/enderecos/:enderecoId" component={EditAddressPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/recuperar-senha" component={RecoverPage} />
        <Route path="/meus-pedidos/:pedidoId" component={OrderPage} />
        <Route path="/meus-pedidos" component={OrdersPage} />
        <Route path="/tecidos" component={TecidosPage} />
        <Route path="/meus-tecidos" component={TecidosUsuarioPage} />
        <Route path="/upload-tecido" component={UploadTecidoUsuarioPage} />
        <Route exact path="/" component={CatalogoPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </ToastsContainer>
  </div>
);
