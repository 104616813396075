import PropTypes from 'prop-types';
import { Dialog } from 'react-bootstrap-utils';
import { Link, useLocation } from 'react-router-dom';

import { LoaderIcon } from 'shared/components/LoaderIcon';
import { Icon } from 'shared/components/Icon';
import { SidebarOpener } from 'shared/components/SidebarOpener';
import { SquareBox } from 'shared/components/SquareBox';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useUserFabrics } from 'shared/http/user-fabrics.http';
import { UserFabricDetails } from 'shared/components/UserFabricDetailsDialog';

export function TecidosUsuarioPage() {
  const { userFabrics: tecidosUsuario, isLoading } = useUserFabrics();

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="tecidos-usuario">
      <div className="menu">
        <SidebarOpener />
        <div className="titulo">Meus tecidos</div>
        <span />
      </div>

      <div className="conteudo">
        {tecidosUsuario.length === 0 ? (
          <EmptyUserFabrics />
        ) : (
          <div className="listagem">
            {tecidosUsuario.map((tecido) => (
              <div key={tecido._id} className="tecido-usuario">
                <SquareBox>
                  <UserFabricDetailsDialog userFabric={tecido}>
                    <div className="box-tecido">
                      <img src={tecido.images.thumbnail.url || '/assets/image-placeholder.png'} alt="Imagem Tecido" crossOrigin="anonymous" />

                      <span className="info-imagem">
                        <Icon icon="information" />
                      </span>
                    </div>
                  </UserFabricDetailsDialog>
                </SquareBox>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function EmptyUserFabrics() {
  const location = useLocation();

  return (
    <div className="text-light m-4">
      <p>Nenhum tecido adicionado ainda. Navegue para o catálogo e faça um novo upload de tecido.</p>
      <div className="text-center">
        <Link to={{ ...location, pathname: '/' }} className="btn btn-success mb-3">
          Voltar
        </Link>
      </div>
    </div>
  );
}

function UserFabricDetailsDialog({ userFabric, children }) {
  const { navigate } = useNavigate();

  function onSelect(close) {
    close();
    navigate('/', {
      'tecido-usuario': userFabric._id,
      'amostra-tecido': undefined,
      'subgrupo-tecido': undefined,
      'grupo-tecido': undefined,
      tecido: undefined,
    });
  }

  return (
    <Dialog body={({ close }) => <UserFabricDetails userFabric={userFabric} onSelect={() => onSelect(close)} />}>
      {children}
    </Dialog>
  );
}
UserFabricDetailsDialog.propTypes = {
  userFabric: PropTypes.object,
  children: PropTypes.node,
};
