import PropTypes from 'prop-types';

import { Icon } from 'shared/components/Icon';

export function TamanhosSelecionados({ order, update, avancar }) {
  const table = order.getTable();

  function proceed() {
    const sizes = order.getSizes();

    order.setSizes(sizes);
    update(order);

    setTimeout(() => {
      avancar();
    });
  }

  return (
    <div className="passo tamanhos-selecionados">
      <div className="content">
        <table className="table table-sm table-borderless">
          <thead>
            <tr className="text-center">
              <th width="4%" />
              <th width="23%">
                <div className="item">Busto</div>
              </th>
              <th width="23%">
                <div className="item">Cintura</div>
              </th>
              <th width="23%">
                <div className="item">Quadril</div>
              </th>
              <th width="23%">
                <div className="item">Ombro</div>
              </th>
              <th width="4%" />
            </tr>
          </thead>
          <tbody>
            {table.measures.map((measure) => (
              <tr key={measure._id} className="text-center">
                <td className={measure.selected ? 'selecionado' : ''}>
                  {measure.referenceModel && (
                    <div className="modelo-prova">
                      <Icon icon="tamanho-prova" />
                    </div>
                  )}
                </td>

                <td>
                  <div className="item">{measure.bust}</div>
                </td>
                <td>
                  <div className="item">{measure.waist}</div>
                </td>
                <td>
                  <div className="item">{measure.hip}</div>
                </td>
                <td>
                  <div className="item">{measure.shoulder}</div>
                </td>
                <td className={measure.selected ? 'selecionado' : ''} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="acao">
        <button className="btn btn-lg btn-block btn-primary" onClick={proceed}>
          Confirmar
        </button>
      </div>
    </div>
  );
}

TamanhosSelecionados.propTypes = {
  avancar: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};
