import PropTypes from 'prop-types';

import { SVGModelo } from 'shared/components/SVGModelo';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { getValue } from 'shared/helpers/getters-setters';
import { useModelTypes } from 'shared/http/model-types.http';
import { useSearch } from 'shared/helpers/useSearch';

export const TiposModelo = ({ selecionarTipo, tecido, tecidoUsuario }) => {
  const { 'subgrupo-tecido': subgrupoId } = useSearch();
  const { modelTypes: tiposModelo, isLoading } = useModelTypes({ 'by_subgroup_ids': subgrupoId }, true);

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="tipos-modelo">
      {tiposModelo.map((tipoModelo) => (
        <div key={tipoModelo._id} className="tipo-modelo" onClick={() => selecionarTipo(tipoModelo)}>
          <div className="svg">
            <SVGModelo
              svg={getValue(tipoModelo, 'representative.default_unfolding.nodes')}
              tecido={tecido ? tecido : null}
              tecidoUsuario={tecidoUsuario ? tecidoUsuario : null}
            />
          </div>
          <div className="label">{tipoModelo.name}</div>
        </div>
      ))}
      {tiposModelo.length === 0 && (
        <small className="text-light p-3">Nenhum modelo foi encontrado para o tecido selecionado</small>
      )}
    </div>
  );
};

TiposModelo.propTypes = {
  selecionarTipo: PropTypes.func.isRequired,
  tecido: PropTypes.object,
  tecidoUsuario: PropTypes.object,
};
