import { Link } from 'react-router-dom';

import { SidebarOpener } from 'shared/components/SidebarOpener';
import { formatMoney } from 'shared/helpers/formatters';
import { LoaderIcon } from 'shared/components/LoaderIcon';
import { useShoppingCart, removeShoppingCartItem } from 'shared/http/shopping-cart.http';
import { useSession } from 'shared/http/auth.http';
import { useNavigate } from 'shared/helpers/useNavigate';
import { useAnnouncement } from 'shared/components/Announcement';
import { DigitalSalesBanner } from 'shared/components/DigitalSalesBanner';
import { trackCheckoutStart } from 'shared/analytics';

import { ShoppingCartItem } from './ShoppingCartItem';

export function CarrinhoComprasPage() {
  const { navigate } = useNavigate();
  const { isAuthenticated, isLoading: isLoadingSession } = useSession();

  const { shoppingCart, isLoading, refresh } = useShoppingCart();
  const { showAnnouncement, AnnouncementPortal } = useAnnouncement();

  function removerItem(item) {
    removeShoppingCartItem(item).then(() => {
      refresh();
    });
  }

  function pay() {
    trackCheckoutStart(shoppingCart);
    if (showAnnouncement()) {
      return;
    }

    const paymentUrl = '/carrinho-compras/pagamento';

    if (isAuthenticated) {
      navigate(paymentUrl);
    } else {
      navigate('login', {
        redirectTo: paymentUrl,
      });
    }
  }

  const carrinhoVazio = !shoppingCart || !shoppingCart.items || shoppingCart.items.length === 0;

  if (isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className="carrinho-compras-page">
      <DigitalSalesBanner />
      <AnnouncementPortal />
      <div className="menu">
        <SidebarOpener />
        <div className="total">Total: {formatMoney(shoppingCart.total)}</div>
      </div>

      <div className="container-fluid">
        {carrinhoVazio ? (
          <EmptyShoppingCart />
        ) : (
          <div className="itens">
            {shoppingCart.items.map((item) => (
              <div key={item._id} className="item">
                <ShoppingCartItem item={item} removeItem={removerItem} />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="actions">
        <button
          className="btn btn-lg btn-primary btn-block"
          disabled={shoppingCart.total <= 0 || isLoadingSession}
          onClick={pay}
          type="button"
        >
          Comprar
        </button>
      </div>
    </div>
  );
}

function EmptyShoppingCart() {
  return (
    <div className="text-light mt-3">
      <h3 className="text-light text-center">Seu carrinho está vazio!</h3>
      <h5 className="text-light text-center mb-3">
        Você ainda não escolheu produtos para comprar. Navegue para o nosso catálogo e monte seu pedido.
      </h5>

      <div className="text-center m-5">
        <Link to="/" className="btn btn-success text-light">
          Voltar para o catálogo
        </Link>
      </div>
    </div>
  );
}

